.drop-file-label input {
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.drop-file-label:hover {
  opacity: 0.8;
}

.dragover {
  border: 2px dashed #1bc5bd;
  opacity: 0.6;
}
