.title {
    font-size: 40px;
    font-weight: 700;
    color: #507712;
}

.product-name {
    font-size: 25px;
    line-height: 30px;
    font-weight: 700;
    // font-family: "Roboto";
}

.product-price {
    font-size: 17px;
    color: #ff2c2c;
    font-weight: 500;
}

.product-sale-price {
    font-size: 17px;
    color: gray;
    font-weight: 500;
}

.product-sale-percent {
    font-size: 17px;
    color: #ff2c2c;
    font-weight: 500;
    background-color: #ffd8d8;
    padding: 5px;
}

.product-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    margin-top: 10vh;
}


@media only screen and (max-width: 600px) {
    .title {
        font-size: 21px
    }

    .product-item {
        grid-template-columns: 1fr
    }

    .product-name {
        font-size: 12px;
    }

    .product-price, .product-sale-percent, .product-sale-price{
        font-size: 11px;
    }

    .product-container {
        margin-top: 5vh;
        gap: 20px 10px;
    }
}
