.text-content {
    font-size: 16px;
    font-weight: 500;
    text-align: justify;
    line-height: 1.25;
}

.about-section-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    margin-top: 10vh;
}

.about-image-container {
    border-radius: 15px;
    overflow: hidden;
    height: fit-content;

    img {
        object-fit: contain;
        width: 100%;
    }
}

@media only screen and (max-width: 600px) {
    .about-section-container {
        grid-template-columns: 1fr;
    }

    .about-image-container {
        order: -1;
    }

    .text-content {
        font-size: 14px;
    }
}
