.productCategory-image-preview{
  max-height: 400px;
  object-fit: contain;
}
.image-wrapper-preview{
  position: relative;
  .remove-image{
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    font-size: 25px;
  }
}