@media screen {
  .order-print-area {
    visibility: hidden;
  }
}

.order-print-area {
  width: 100%;
  padding: 30px 20px;
  table {
    width: 100%;
    margin-top: 10px;
  }
  td,
  th {
    border: 1px solid black;
    text-align: left;
    padding: 5px;
    font-size: 10px;
  }
  th {
    background-color: #ebebeb;
    font-size: 10px;
    font-weight: bold;
    text-align: center;
  }
  .border-none{
    border: none;
  }
  .user{
    display: flex;
    justify-content: space-around;
    margin-top:20px;
  }
  .end{
    margin-top: 70px;
    text-align: center;
    font-size: 16px;
  }
  .info-payment{
    td{
      padding: 1px 5px;
    }
  }
}
