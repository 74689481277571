.footer-container {
    margin-top: 10vh;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 3rem;
    align-items: start;
    justify-items: center;
    padding-top: 2rem;
    padding-bottom: 2rem;
}

@media only screen and (max-width: 600px) {
    .footer-container {
        grid-template-columns: 1fr
    }

    .section-title {
        text-align: center;
    }
}
