.product-desc{
   overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 2; /* number of lines to show */
           line-clamp: 2;
   -webkit-box-orient: vertical;
}

.cart-order{
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 60px;
  .cart-icon{
    font-size: 30px;
  }
}
.total-package-product{
  margin-top: auto;
}

.page-qr{
  background-color: #dadfd5;
}
.minus-sign{
  position: absolute;
  right: 90px
}
.minus-sign-order{
  position: absolute;
  right: 62px
}