.product-item {
    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: 1rem;
    width: fit-content;
}

.product-item-quantity {
    display: inline-grid;
    grid-template-columns: 25px 25px 25px;
    grid-gap: 1px;
    margin-inline: 1rem;
    margin-top: 1rem;
    .product-item-quantity-inner{
        aspect-ratio: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        outline: 1px solid black;
    }
}

.quantity-text {
    font-size: 17px;
    font-weight: 500;
}

@media only screen and (max-width: 600px) {
    .product-item {
        display: block;
    }
    .quantity-text {
        font-size: 14px;
        font-weight: 500;
    }

    .product-item-quantity {
        grid-template-columns: 20px 20px 20px;
        margin-inline: .5rem;
    }
}
