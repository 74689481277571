@import '~core/assets/sass/core/components/variables';


.sheet-page-container {
  background-color: var(--kt-body-bg);
  height: 100%;
  border-radius: $border-radius;

  .tab-content {
    height: calc(100vh - 199px);

    .tab-pane {
      height: 100%;
    }
  }

  .sheet-kanban-board {
    height: calc(100% - 70px);
  }
}

.workspace-page-menu {
  background-color: var(--kt-body-bg);
  border-radius: $border-radius;
  height: calc(100vh - 80px);
}


.offcanvas-edit-view.offcanvas {
  top: 236px;
}

.view-toolbar-separator {
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
}


.sheet-view-toolbar {
  background-color: var(--kt-body-bg);
  position: sticky;
  top: 0;
  left: 0;
  z-index: 90;
}