.hero-title {
    font-size: 39px;
    color: #000000;
    font-weight: 500;
    // font-family: "Roboto";
}

.hero-heading {
    font-size: 82px;
    line-height: 83px;
    color: #507712;
    font-weight: 700;
    text-transform: uppercase;
    margin-top: 2rem;
    // font-family: "Roboto";
}

ul {
    li {
        font-size: 18px;
        color: #000000;
        font-weight: 500;
        // font-family: "Roboto";
        text-align: left;
    }
}

.hero-section-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 10vh;
}

.logo {
    width: 300px;
    height: 150px;
}

.hero-image {
    background-image: url('./assets/tinhdau.png');
    background-position: center bottom;
    background-size: 70%;
    object-position: bottom;
    background-repeat: no-repeat;
    object-fit: contain;
    aspect-ratio: 1 / 1;
    // Fallback
    @supports not (aspect-ratio: 1 / 1) {
    &::before {
      float: left;
      padding-top: 100%;
      content: "";
    }

    &::after {
      display: block;
      content: "";
      clear: both;
    }
  }
    width: 100%;
}

.image-mobile {
    display: none;
}

.image-desktop {
    display: block;
}

@media only screen and (max-width: 950px) {
    .hero-title {
        font-size: 26px;
    }
}

@media only screen and (max-width: 600px) {
    .hero-section-container {
        grid-template-columns: 1fr;
        gap: 1rem;
        margin-top: 2rem;
    }
    .hero-title {
        font-size: 14px;
    }
    .hero-heading {
        margin-top: 0;
        font-size: 27px;
        line-height: 1;
    }

    ul {
        display: inline-flex;
        justify-content: center;
        gap: 2.5rem;
        // list-style: none;
        padding: 0;
        li {

            font-size: 14px;
        }
    }

    .hero-content {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .logo {
        width: 100px;
        height: 50px;
    }

    .image-mobile {
        display: block;
    }
    
    .image-desktop {
        display: none;
    }

    .hero-image{
        min-height: 300px;
    }
}

.section-list-product{
    column-gap: 25px;
}