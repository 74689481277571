.container {
    width: 80%;
}

.image {
  object-fit: contain;
  aspect-ratio: 1;  
}

.section-container {
    display: flex;
}

.section-title {
    font-size: 20px;
    font-weight: 700;
    line-height: 39px;
    color: #4a6811;
}

.title-decoration {
    text-decoration: underline;
    text-decoration-color: #4a6811;
}

p {
    font-size: 14px;
    font-weight: 500;
    line-height: 19px;
}

.order-page-button-primary {
    width: 248px;
    height: 62px;
    border-radius: 14px;
    background-color: #d3320d;
    font-size: 36px;
    color: #ffffff;
    font-weight: 500;
    text-transform: uppercase;
    border:none
}

@media only screen and (max-width: 600px) {
    .section-container {
      flex-direction: column;
    }

    .order-page-button-primary {
        width: 150px;
        font-size: 19px;
        height: 33px;
    }

    .container {
        width: 100%;
        padding: 0;
    }
}
