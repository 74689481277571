/* use 2 class not working - > use 3 class */
.btn.pagination.active{
    color: var(--kt-pagination-active-color) !important;
    background-color: var(--kt-pagination-active-bg) !important;
    
}
.btn.pagination.active:hover{
    color: var(--kt-pagination-active-color) !important;
    background-color: var(--kt-pagination-active-bg) !important;
    
}