.customer{
    margin-left: 30px;
  }
.product{
  margin-right: 30px;
}
.report{
  background-color: #E9ECFF;
  height: 72px;
  align-items: center;
}
.custom-date-picker {
  height: 40px; /* Set your desired height */
  padding: 0 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
}
.react-datepicker-wrapper{
  width: 90%;
}
// start custom asynSelect - report
// ex: .css-3w2yfm-ValueContainer
.product-filter-select [class*="css"][class$="ValueContainer"]//Select all classes that contain 'css' and end with 'ValueContainer'
{
  flex-wrap: nowrap !important;
  display:flex !important;
  overflow-x: scroll !important;
}
//ex: .css-1p3m7a8-multiValue
.product-filter-select [class*="css"][class$="multiValue"]{//Select all classes that contain 'css' and end with 'multiValue'

  min-width: min-content !important;
}
// end custom asynSelect - reports