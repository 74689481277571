.ql-toolbar.ql-snow {
  border: 1px solid #e4e6ef;
  box-sizing: border-box;
  padding: 8px;
  border-top-left-radius: 0.42rem;
  border-top-right-radius: 0.42rem;
}

.ql-toolbar.ql-snow + .ql-container.ql-snow {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-top: 0px;
}

.ql-container.ql-snow {
  border: 1px solid #e4e6ef;
  border-radius: 0.42rem;
  border-bottom-left-radius: 0.42rem;
  border-bottom-right-radius: 0.42rem;
  height: 80px;
}
